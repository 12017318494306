import React, { useCallback, useEffect, useRef, useState } from 'react'
// @ts-ignore
import TimeMe from 'timeme.js'

import { GlobalTrackingProvider, TrackingProperties } from '@flock/shared-ui'
import { UserEventType } from '@flock/utils'
import { trackPage, track } from './analytics'
import { GATSBY_ENV } from '../constants'

// https://codesandbox.io/s/useinterval-demo-typescript-3zddk?file=/src/index.tsx:755-812
interface IUseInterval {
  (callback: () => void, interval: number): void
}

const useInterval: IUseInterval = (callback, interval) => {
  const savedCallback = useRef<(() => void) | null>(null)
  // After every render, save the latest callback into our ref.
  useEffect(() => {
    savedCallback.current = callback
  })

  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current()
      }
    }

    const id = setInterval(tick, interval)
    return () => clearInterval(id)
  }, [interval])
}

type TrackingWrapperProps = {
  children: React.ReactNode
  groupedEvents?: { [key: string]: string }
}

const TrackingWrapper = (props: TrackingWrapperProps) => {
  const { children, groupedEvents } = props
  // Heartbeat fn to capture page_visit duration as it occurs
  const [heartbeat, setHeartbeat] = useState(0)
  const [activeTime, setActiveTime] = useState(0)
  const [currentPage, setCurrentPage] = useState('')
  useInterval(() => {
    setHeartbeat(heartbeat + 15)
    if (typeof window !== `undefined`) {
      if (currentPage !== window.location.pathname) {
        setCurrentPage(window.location.pathname)
        setHeartbeat(0)
      }
    }

    // Stop saving after 10 minutes on a page or if user is inactive
    if (
      heartbeat !== 0 &&
      activeTime !== TimeMe.getTimeOnCurrentPageInSeconds() &&
      heartbeat < 600 &&
      GATSBY_ENV === 'production'
    ) {
      setActiveTime(TimeMe.getTimeOnCurrentPageInSeconds())
      track('pageview-duration', {
        timeOnPage: TimeMe.getTimeOnCurrentPageInSeconds(),
        totalTimeOnPage: heartbeat,
        pageViewed: currentPage,
        actionType: UserEventType.PAGE_DURATION,
      })
    }
  }, 15000)

  useEffect(() => {
    TimeMe.initialize({
      currentPageName: window?.location?.pathname || 'unknown', // current page
      idleTimeoutInSeconds: 5, // seconds
    })

    TimeMe.startTimer()
  }, [])
  const trackingFunctionWrapper = useCallback(
    (name: string, properties?: TrackingProperties) => {
      track(name, properties)
    },
    []
  )

  const trackPageFunctionWrapper = useCallback(
    (name: string, properties?: TrackingProperties) => {
      trackPage(name, properties)
    },
    []
  )

  return (
    <GlobalTrackingProvider
      siteName="landing-fe"
      trackFn={trackingFunctionWrapper}
      trackPageFn={trackPageFunctionWrapper}
      groupedEvents={groupedEvents || {}}
    >
      {children}
    </GlobalTrackingProvider>
  )
}

TrackingWrapper.defaultProps = {
  groupedEvents: {},
}

export default TrackingWrapper
