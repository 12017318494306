// These set up types for segment analytics
/// <reference types="@types/segment-analytics" />
import { TrackingProperties } from '@flock/shared-ui'
import { localStore, UserEventType } from '@flock/utils'
import { v4 as uuidv4 } from 'uuid'
// @ts-ignore
import TimeMe from 'timeme.js'

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS
  }
}

const EVENT_TOKEN_KEY = 'FLOCK-EVENT-TOKEN'

// If logSegment is true, events are not sent to segment and are
// instead logged to the console.
const logSegment = process.env.GATSBY_LOG_SEGMENT === 'true'

const debugSegment = () =>
  logSegment || window.location.href.includes('localhost')

export const shouldTrack = () => {
  let doNotTrack = false
  if (typeof window !== 'undefined') {
    doNotTrack =
      window.doNotTrack === '1' ||
      navigator.doNotTrack === 'yes' ||
      navigator.doNotTrack === '1' ||
      localStore?.getItem('disableTracking') === 'true' ||
      debugSegment()
  }
  return !doNotTrack
}

export const getEventTokenUUID = (): string => {
  let token = localStore?.getItem(EVENT_TOKEN_KEY)
  if (!token) {
    token = uuidv4()
    localStore?.setItem(EVENT_TOKEN_KEY, token!)
  }
  return token!
}

export const getQueryParamsAsJson = () => {
  if (typeof window !== 'undefined') {
    const { search } = window.location
    const params = new URLSearchParams(search)
    const queryParams: { [k: string]: string } = {}
    params.forEach((value: string, key: string) => {
      queryParams[key] = value
    })
    return queryParams
  }
  return {}
}

export const track = (name: string, properties?: TrackingProperties) => {
  const savedParamsString = localStore?.getItem('queryParams') || '{}'
  const savedParams = JSON.parse(savedParamsString)

  const newProperties = {
    ...savedParams,
    ...properties,
    eventToken: getEventTokenUUID(),
  }
  if (!shouldTrack()) {
    return
  }
  if (debugSegment()) {
    console.log('Segment Track', name, newProperties)
  } else {
    // @ts-ignore
    if (window.dataLayer) {
      // @ts-ignore
      window.dataLayer.push({ event: name })
    }

    window.analytics.track(name, newProperties)
  }
}

export const trackPage = (name: string, properties: any) => {
  let queryParams: { [k: string]: string } = {}
  if (typeof window !== 'undefined') {
    const { search } = window.location
    const params = new URLSearchParams(search)
    params.forEach((value: string, key: string) => {
      queryParams[key] = value
    })
    // tracks the page we just came from
    if (TimeMe.currentPageName !== 'default-page-name') {
      track(name, {
        timeOnPage: TimeMe.getTimeOnCurrentPageInSeconds(),
        pageViewed: TimeMe.currentPageName,
        actionType: UserEventType.PAGE_DURATION,
      })
    }

    TimeMe.stopTimer()
    TimeMe.resetAllRecordedPageTimes()

    TimeMe.setCurrentPageName(window?.location?.pathname)
    TimeMe.startTimer()
  }

  const savedParamsString = localStore?.getItem('queryParams') || '{}'
  const savedParams = JSON.parse(savedParamsString)
  queryParams = {
    ...savedParams,
    ...queryParams,
  }
  localStore?.setItem('queryParams', JSON.stringify(queryParams))

  const newProperties = {
    ...properties,
    ...queryParams,
    eventToken: getEventTokenUUID(),
    actionType: UserEventType.PAGE_VISIT,
  }
  if (!shouldTrack()) {
    return
  }

  if (debugSegment()) {
    console.log('Segment Page', name, properties)
  } else {
    // this still goes to segment but specifically triggers and event in google analytics.
    window.analytics.page({
      title: name,
      url: `${window.location.origin}/${name}`,
      path: `/${name}`,
      search: `${window.location.search}`,
    })
  }

  // edit properties

  track(name, newProperties)
}

export type IdentifyProperties = {
  userId?: string
  email?: string
  name?: string
  phone?: string
  brokerUuid?: string
  utmSource?: string
  utmCampaign?: string
  utmMedium?: string
}

export const identify = (properties: IdentifyProperties) => {
  if (!shouldTrack()) {
    return
  }

  if (debugSegment()) {
    console.log('Segment Identify', properties)
  } else if (properties.userId) {
    const { userId, ...otherProperties } = properties
    window.analytics.identify(userId, {
      ...otherProperties,
    })
  } else {
    window.analytics.identify({
      ...properties,
    })
  }
}

export const getGAClientID = () => {
  // cookie is stored in this format '_ga=GA1.2.358520647.1656458798; _gcl=cookie2'
  if (typeof window !== 'undefined' && window.document.cookie !== '') {
    const match = window.document.cookie.match(/_ga=(.+?)(;|$)/)
    if (match !== null && match.length > 1) {
      return match[1].split('.').slice(-2).join('.')
    }
  }
  return ''
}
