// eslint-disable-next-line import/prefer-default-export
export const flattenObject = (obj: { [key: string]: any }) => {
  // The object which contains the
  // final result
  const result: any = {}

  if (!obj) {
    return obj
  }

  // loop through the object "ob"
  Object.keys(obj).forEach((key: string) => {
    // We check the type of the i using
    // typeof() function and recursively
    // call the function again
    if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      const temp = flattenObject(obj[key])
      if (!temp) {
        return
      }
      Object.keys(temp).forEach((subKey: string) => {
        result[`${key}.${subKey}`] = temp[subKey]
      })
    } else if (Array.isArray(obj[key])) {
      result[key] = JSON.stringify(obj[key])
    } else {
      result[key] = obj[key]
    }
  })
  return result
}
