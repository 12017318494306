import { flockTheme } from '@flock/flock-component-library'
import { ThemeProvider } from '@mui/material'
import React from 'react'
import ConfiguredApolloProvider from './apollo'
import TrackingWrapper from './TrackingWrapper'

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({ element }: any) => (
  <ConfiguredApolloProvider>
    <TrackingWrapper>
      <ThemeProvider theme={flockTheme}>{element}</ThemeProvider>
    </TrackingWrapper>
  </ConfiguredApolloProvider>
)
