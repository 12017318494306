// @ts-nocheck
import { createTheme } from '@mui/material/styles'
import flockColors from '../flockColors/flockColors'

const flockTheme = createTheme({
  palette: {
    primary: {
      main: flockColors.blue,
    },
    secondary: {
      main: flockColors.darkGray,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 767,
      md: 1200,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: 'Inter!important',
    h1: {
      fontWeight: 'bold',
      fontSize: '2.1875rem',
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      lineHeight: '2rem',
    },
    h3: {
      fontSize: '1.125rem',
      fontWeight: 'bold',
      lineHeight: '150%',
    },
    h4: {
      fontSize: '0.875rem',
      fontWeight: 'bold',
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
    light: {
      fontWeight: 300,
    },
    black: {
      fontSize: '2.5rem',
      fontWeight: 900,
    },
    miniTitle: {
      fontSize: '1.5rem',
      fontWeight: 900,
    },
  } as any,
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter!important',
          fontSize: '1.125rem',
          textTransform: 'none',
          cursor: 'pointer',
        },
      },
    },
  },
})

export default flockTheme
