/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, useContext } from 'react'

type FlattenedTrackProperties = {
  [key: string]: string
  actionType: string
}

export const GlobalTrackingContext = createContext({
  trackFn: (_: string, __: FlattenedTrackProperties) => {},
  trackPageFn: (_: string, __: FlattenedTrackProperties) => {},
  groupedEvents: {} as { [key: string]: string },
})

export const useGlobalTracking = () => useContext(GlobalTrackingContext)
