import { flattenObject, UserEventType } from '@flock/utils'
import React, { useCallback, useMemo, useState } from 'react'
import { useGlobalTracking } from '../GlobalTrackingContextProvider/useGlobalTracking'
import useTracking, {
  TrackingNode,
  TrackingProperties,
  TrackingContext,
} from '../useTracking/useTracking'

type TrackingContextProviderProps = {
  name: string
  initialTrackingProperties?: {
    [key: string]: any
  }
  children: React.ReactNode
}

const TrackingContextProvider = ({
  name,
  initialTrackingProperties,
  children,
}: TrackingContextProviderProps) => {
  const [trackingProperties, setTrackingProperties] = useState(
    initialTrackingProperties || {}
  )
  const globalTrackingContext = useGlobalTracking()
  const trackFn = useMemo(
    () => globalTrackingContext.trackFn || (() => {}),
    [globalTrackingContext.trackFn]
  )
  const trackPageFn = useMemo(
    () => globalTrackingContext.trackPageFn || (() => {}),
    [globalTrackingContext.trackPageFn]
  )
  const groupedEvents = useMemo(
    () => globalTrackingContext.groupedEvents || {},
    [globalTrackingContext.groupedEvents]
  )

  // Check the outer context for a provider. If there is one, this is a nested
  // context provider and we need to combine the tracking context with the outer context.
  const parentContext = useTracking()
  const trackingNode = useMemo(() => {
    const node: TrackingNode = {
      name,
      ...trackingProperties,
    }

    if (parentContext?.trackingNode) {
      node.parent = parentContext.trackingNode
    }

    return node
  }, [parentContext?.trackingNode, name, trackingProperties])

  // For each tracking function, optionally provide an eventName to be able to
  // specify the component that is firing the event as well as additional properties
  // related to the event.
  const trackPage = useCallback(
    (
      pageName: string,
      properties: TrackingProperties = { actionType: UserEventType.PAGE_VISIT }
    ) => {
      if (trackPageFn) {
        const pageNode = {
          ...properties,
          parent: trackingNode,
        }
        const flattenedProperties = flattenObject(pageNode)
        trackPageFn(pageName, flattenedProperties)
      }
    },
    [trackingNode, trackPageFn]
  )

  const getSlug = useCallback((node: TrackingNode): string => {
    if (node.parent) {
      return `${getSlug(node.parent)}/${node.name}`
    } else {
      return node.name
    }
  }, [])
  const slug = useMemo(
    () => getSlug(trackingNode),
    // parentContext required here since getSlug uses node.parent
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trackingNode, getSlug, parentContext?.trackingNode]
  )
  const track = useCallback(
    (
      eventName: string,
      properties: TrackingProperties = { actionType: UserEventType.UNSPECIFIED }
    ) => {
      if (trackFn) {
        const eventNode = {
          ...properties,
          parent: trackingNode,
        }
        const flattenedProperties = flattenObject(eventNode)
        // this ends up becoming a unique identifier for the event
        // since slug = what part of page, eventname = what specifically was interacted with
        flattenedProperties.slug = `${slug}/${eventName}`
        trackFn(eventName, flattenedProperties)

        Object.keys(flattenedProperties).forEach((key: string) => {
          Object.keys(groupedEvents).forEach((groupedEventKey: string) => {
            if (key.includes(groupedEventKey)) {
              const newProperties = {
                ...flattenedProperties,
                eventName,
                [groupedEventKey]: flattenedProperties[key],
              }

              trackFn(groupedEvents[groupedEventKey], newProperties)
            }
          })
        })
      }
    },
    [trackFn, trackingNode, groupedEvents, slug]
  )

  if (!track || !trackPage) {
    return <>children</>
  }

  return (
    <TrackingContext.Provider
      value={{
        trackingNode,
        updateTrackingProperties: (properties: TrackingProperties) =>
          setTrackingProperties(properties),
        track,
        trackPage,
      }}
    >
      {children}
    </TrackingContext.Provider>
  )
}

export default TrackingContextProvider

TrackingContextProvider.defaultProps = {
  initialTrackingProperties: null,
}
