const flockColors = {
  blue: '#367DE8',
  hoverBlue: '#5292f2',
  neonBlue: '#36D2E8',
  darkBlue: '#345581',
  slateBlue: '#7A97C7',
  lightSlateBlue: '#8CA8D1',
  darkerBlue: '#0F1B39',
  lightBlue: '#C3F2FC',
  lighterBlue: '#D6F0FF',
  black: '#000000',
  white: '#FFFFFF',
  lighterGray: '#F3F3F3',
  lightGray: '#E4E4E4',
  darkGray: '#6C6B6B',
  darkerGray: '#3E3E3E',
  green: '#1aa33f',
  aquamarine: '#67CEAA',
  orange: '#EA5917',
  red: '#D44011',
  salmon: '#FA8072',
  yellow: '#FFC700',
  paleBlue: '#D8E7FF',
}

export default flockColors
